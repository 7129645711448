import { ReportActions, ReportActionTypes } from './report.actions';
import { Report } from '../report.model';
import { CallingState, CallState } from '../../state/app.state';
import { QuestionType } from '../../questions/questions.model';
import { mapTo, takeUntil, timer } from 'rxjs';

export interface ReportState {
  reports: Report[];
  currentReportIdBeingEdited: string;
  questionsForCurrentReport: QuestionType[];
  callState: CallState;
  reportIdCreated: string;
}

const initialState: ReportState = {
  reports: [],
  currentReportIdBeingEdited: '',
  questionsForCurrentReport: [],
  callState: CallingState.INIT,
  reportIdCreated: '',
};

export function reportReducer(
  state = initialState,
  action: ReportActions
): ReportState {
  switch (action.type) {
    case ReportActionTypes.LOAD_REPORTS:
      return {
        ...state,
        callState: CallingState.CALLING,
      };
    case ReportActionTypes.LOAD_REPORTS_SUCCESS:
      return {
        ...state,
        callState: CallingState.CALLED,
        reports: action.reports,
      };
    case ReportActionTypes.LOAD_REPORTS_FAILURE:
      return {
        ...state,
        callState: CallingState.CALLED,
      };
    case ReportActionTypes.SET_REPORT_ID_BEING_EDITED:
      return {
        ...state,
        currentReportIdBeingEdited: action.reportId,
      };
    case ReportActionTypes.CREATE_REPORT:
      return {
        ...state,
        reportIdCreated: '',
      };
    case ReportActionTypes.CREATE_REPORT_SUCCESS:
      return {
        ...state,
        reportIdCreated: action.report.reportId,
        reports: AddReport(state.reports, action.report),
      };
    case ReportActionTypes.DELETE_REPORT_SUCCESS:
      return {
        ...state,
      };
    case ReportActionTypes.SAVE_REPORT:
      return {
        ...state,
        callState: CallingState.CALLING,
      };
    case ReportActionTypes.SAVE_REPORT_SUCCESS:
      return {
        ...state,
        callState: CallingState.CALLED,
      };
    case ReportActionTypes.SAVE_REPORT_FAILURE:
      return {
        ...state,
        callState: CallingState.CALLED,
      };
    case ReportActionTypes.SET_REPORT_QUESTIONS:
      return {
        ...state,
        questionsForCurrentReport: action.questions,
      };
    case ReportActionTypes.GET_REPORT_QUESTIONS:
      return {
        ...state,
      };
    default: {
      return state;
    }
  }
}

const AddReport = (reports: Report[], report: Report): Report[] => {
  const updatedReports = [...reports];
  updatedReports.push(report);
  return updatedReports;
};


// const SetQuestions = (
//   reports: Report[],
//   reportId: string,
//   questions: QuestionType[]
// ): QuestionType[] => {
//   const updatedReports = [...reports];
//   return updatedReports.map((report: Report) => {
//     if (report.reportId === reportId) {
//       return {
//         ...report,
//         questions: questions,
//       };
//     }
//     return report;
//   });
// };
